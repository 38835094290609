import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';
import {
    applicationType,
    downloadApplicationsType,
    inactiveApplicationsType,
    newApplicationType,
    updateApplicationType
} from '../utils/types';

export const applicationsApi = createApi({
    reducerPath: 'applications',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['applications', 'downloadApplications', 'inactiveApplications'],
    endpoints: (builder) => ({
        getApplications: builder.query<applicationType[], void>({
            query: () => ({
                url: 'aplicacion'
            }),
            providesTags: ['applications'],
            transformResponse: (response: { data: { aplicaciones: any } }) =>
                response?.data?.aplicaciones
        }),
        getDownloadApplications: builder.query<applicationType[], downloadApplicationsType>({
            query: (filters: downloadApplicationsType) => ({
                url: 'aplicacion',
                params: filters
            }),
            providesTags: ['downloadApplications'],
            transformResponse: (response: { data: { aplicaciones: any } }) =>
                response?.data?.aplicaciones
        }),
        getInactiveApplications: builder.query<applicationType[], inactiveApplicationsType>({
            query: (filters: inactiveApplicationsType) => ({
                url: 'aplicacion',
                params: filters
            }),
            providesTags: ['inactiveApplications'],
            transformResponse: (response: { data: { aplicaciones: any } }) =>
                response?.data?.aplicaciones
        }),
        addApplication: builder.mutation({
            query: (data: newApplicationType) => ({
                url: 'aplicacion',
                body: data,
                method: 'POST'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['applications', 'inactiveApplications'])
        }),
        deleteApplication: builder.mutation({
            query: (appId: number) => ({
                url: `aplicacion/${appId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['applications', 'inactiveApplications'])
        }),
        updateApplication: builder.mutation({
            query: ({ data, appId }: { data: updateApplicationType; appId: number }) => ({
                url: `aplicacion/${appId}`,
                body: data,
                method: 'PUT'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['applications', 'inactiveApplications'])
        }),
        reactivateApplication: builder.mutation({
            query: (appId: number) => ({
                url: `aplicacion/reactivar/${appId}`,
                method: 'PUT'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['applications', 'inactiveApplications'])
        })
    })
});
export const {
    useGetDownloadApplicationsQuery,
    useGetApplicationsQuery,
    useAddApplicationMutation,
    useDeleteApplicationMutation,
    useLazyGetApplicationsQuery,
    useUpdateApplicationMutation,
    useGetInactiveApplicationsQuery,
    useReactivateApplicationMutation
} = applicationsApi;
