import { Menu, MenuItem } from '@mui/material';

function ActionsMenu({ anchorEl, handleCloseMenu, handleSubmitAction, options }: any) {
    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleCloseMenu}
            sx={{
                '& .MuiPaper-root': {
                    color: '#384A52',
                    width: '176px',
                    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)'
                },
                '& .delete': {
                    color: '#971B2F'
                },
                '& li:hover': {
                    background: '#E2ECF3'
                }
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button'
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            {options && options.length && options.includes('view') && (
                <MenuItem onClick={() => handleSubmitAction('view')}>Ver</MenuItem>
            )}
            {options && options.length && options.includes('edit') && (
                <MenuItem onClick={() => handleSubmitAction('edit')}>Editar</MenuItem>
            )}
            {options && options.length && options.includes('reactivate') && (
                <MenuItem onClick={() => handleSubmitAction('reactivate')}>Dar de alta</MenuItem>
            )}
            {options && options.length && options.includes('inactivate') && (
                <MenuItem onClick={() => handleSubmitAction('inactivate')}>Dar de baja</MenuItem>
            )}
            {options && options.length && options.includes('delete') && (
                <MenuItem onClick={() => handleSubmitAction('delete')} className="delete">
                    Eliminar
                </MenuItem>
            )}
        </Menu>
    );
}

export default ActionsMenu;
