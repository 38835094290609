import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';
import { documentType } from '../utils/types';

export const documentTypesApi = createApi({
    reducerPath: 'documentTypes',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['documentTypes'],
    endpoints: (builder) => ({
        getDocumentTypes: builder.query<any, void>({
            query: () => ({
                url: 'tipo-documento'
            }),
            providesTags: ['documentTypes'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        addDocumentType: builder.mutation({
            query: (data: documentType) => ({
                url: `tipo-documento`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (_, error) => (error ? [] : ['documentTypes'])
        }),
        deleteDocumentType: builder.mutation({
            query: (documentTypeId: number) => ({
                url: `tipo-documento/${documentTypeId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['documentTypes'])
        }),
        updateDocumentType: builder.mutation({
            query: ({ data, documentTypeId }) => ({
                url: `tipo-documento/${documentTypeId}`,
                body: data,
                method: 'PUT'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['documentTypes'])
        })
    })
});
export const {
    useGetDocumentTypesQuery,
    useAddDocumentTypeMutation,
    useDeleteDocumentTypeMutation,
    useUpdateDocumentTypeMutation
} = documentTypesApi;
