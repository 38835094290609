import { CbaLogo } from '../../assets/icons/Icons';
import { URL } from '../layout/Layout';
import Button from '../Button/Button';

function UnauthorizedPage() {
    return (
        <div className="unauthorized-page">
            <div style={{ textAlign: 'center' }}>
                <CbaLogo height="108px" width="108px" />
                <h2>¡Usuario no autorizado!</h2>
                <br />
                <Button onClick={() => window.location.replace(URL)}>Cerrar sesión</Button>
            </div>
        </div>
    );
}

export default UnauthorizedPage;
