import {
    useAddDocumentTypeMutation,
    useDeleteDocumentTypeMutation,
    useGetDocumentTypesQuery,
    useUpdateDocumentTypeMutation
} from '../../../services/documentTypesApi';
import { useGetFileTypesQuery } from '../../../services/fileTypesApi';

function useFetchs({ openDialog }: { openDialog: boolean }) {
    const {
        data: items,
        isLoading: loadingItems,
        isFetching: fetchingItems
    } = useGetDocumentTypesQuery();
    const {
        data: fileTypes,
        isLoading: loadingFileTypes,
        isFetching: fetchingFileTypes
    } = useGetFileTypesQuery(undefined, { skip: !openDialog });
    const [addDocumentType, { isLoading: addingDocumentType }] = useAddDocumentTypeMutation();
    const [deleteDocumentType, { isLoading: deletingDocumentType }] =
        useDeleteDocumentTypeMutation();
    const [updateDocumentType, { isLoading: updatingDocumentType }] =
        useUpdateDocumentTypeMutation();

    return {
        items,
        fileTypes,
        loadingFileTypes,
        fetchingFileTypes,
        loadingItems,
        fetchingItems,
        deleteDocumentType,
        addDocumentType,
        updateDocumentType,
        deletingDocumentType,
        addingDocumentType,
        updatingDocumentType
    };
}

export default useFetchs;
