import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Home from '../../../pages/Home/Home';

type SessionIdType = string | null;

const ProtectedRoute = ({ user }: any) => {
    const [searchParams] = useSearchParams();
    const storageSessionId: SessionIdType = sessionStorage.getItem('sesionid');
    const sesionid: SessionIdType = searchParams.get('sesionid');

    useEffect(() => {
        if (!!sesionid) {
            sessionStorage.setItem('sesionid', sesionid);
        }
        // eslint-disable-next-line
    }, [sesionid]);

    if ((sesionid || storageSessionId) && !user) return <Navigate to="/user-validation" replace />;

    return !user ? <Navigate to="/login" replace /> : <Home />;
};

export default ProtectedRoute;
