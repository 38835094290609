import { ApplicationHeaders } from '../../utils/headers';
import AppForm from './components/AppForm';
import DataTable from '../../components/Table/DataTable';
import DialogCdd from '../../components/Dialog/DialogCdd';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useApplications from './hooks/useApplications';
import ActionsMenu from '../../components/ActionsMenu/ActionsMenu';
import { FormControlLabel, Grid, Switch } from '@mui/material';

function Aplicaciones() {
    const {
        data,
        items,
        inactiveApplications,
        showInactiveApplications,
        openDialog,
        addingApp,
        deletingApp,
        updatingApp,
        loadingApplications,
        fetchingApplications,
        filterModelValue,
        setOpenDialog,
        handleCloseDialog,
        handleDelete,
        handleChange,
        handleSubmit,
        onInputChange,
        selectedItem,
        anchorEl,
        handleCloseMenu,
        handleMenuAction,
        handleSubmitAction,
        isEditing,
        handleSwitchAppsInactivas,
        handleDownloadExcel
    } = useApplications();

    return (
        <>
            <SectionTitle>
                Listado de aplicaciones habilitadas para consumir la Web API.
            </SectionTitle>

            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '& svg': {
                        color: 'var(--alternative2)'
                    }
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={showInactiveApplications}
                            name="aplicacionesInactivas"
                            onChange={handleSwitchAppsInactivas}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Ver aplicaciones inactivas"
                />
            </Grid>

            <SearchToolbar
                inputLabel="Buscar aplicación"
                btnText="Nueva Aplicación"
                onButtonClick={setOpenDialog}
                onInputChange={onInputChange}
                downloadButton={items && !!items.length}
                onDownloadButtonClick={handleDownloadExcel}
            />

            <DataTable
                handleDeleteAction={handleDelete}
                handleMenuAction={handleMenuAction}
                headers={ApplicationHeaders}
                withActions={['menu']}
                loading={loadingApplications || fetchingApplications || deletingApp || updatingApp}
                options={showInactiveApplications ? inactiveApplications : items}
                filterModelValue={filterModelValue}
            />

            <ActionsMenu
                anchorEl={anchorEl}
                handleCloseMenu={handleCloseMenu}
                handleSubmitAction={handleSubmitAction}
                options={showInactiveApplications ? ['reactivate'] : ['edit', 'inactivate']}
            />

            <DialogCdd
                title={isEditing ? `Editar ${data.nombre}` : 'Nueva Aplicación'}
                openDialog={openDialog}
                handleSubmit={handleSubmit}
                handleCloseDialog={handleCloseDialog}
                loading={addingApp || updatingApp}
            >
                <AppForm data={data} handleChange={handleChange} isEditing={isEditing} />
            </DialogCdd>
        </>
    );
}

export default Aplicaciones;
