import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout, setToken, setRefreshToken } from '../redux/slices/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}${settings.url.admin}`,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (!headers.has('Content-Type')) {
            headers.set('Content-Type', 'application/json');
        }
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.data?.data?.token) {
        const token = result.data.data.token;
        const refreshToken = result.data.data.refreshToken;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);

        api.dispatch(setToken(token));
        api.dispatch(setRefreshToken(refreshToken));
    }

    if (result.error && result.error?.data?.status === 401) {
        const { auth }: any = api.getState();

        const refreshResult: any = await baseQuery(
            {
                url: 'auth/refresh-token',
                method: 'GET',
                params: { refreshToken: auth.refreshToken }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data?.success) {
            const token = refreshResult.data?.data?.token;
            const refreshToken = refreshResult.data?.data?.refreshToken;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);

            api.dispatch(setToken(token));
            api.dispatch(setRefreshToken(refreshToken));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        validarTokenSesion: builder.mutation({
            query: (sessionId) => ({
                url: 'auth/validar-sesion',
                params: sessionId
            })
        }),
        logout: builder.query({
            query: () => ({
                url: 'auth/cerrar-sesion'
            })
        })
    })
});

export const { useValidarTokenSesionMutation, useLazyLogoutQuery } = authApi;
