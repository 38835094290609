import { Navigate, Route, Routes } from 'react-router-dom';
import { selectCurrentUser } from '../../redux/slices/authSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import Alert from '../Alert/AlertDialog';
import Footer from '../Footer/Footer';
import RoutesManager from './routes/RoutesManager';
import { settings } from '../../settings';
import { useEffect } from 'react';
import UserValidation from './components/UserValidation';
import UnauthorizedPage from '../Unauthorized/Index';
import ProtectedRoute from './components/ProtectedRoute';

export const VEDI_APP_ID: { [key: string]: number } = {
    test: 146,
    stage: 148,
    prod: 154
};

export const URL = `${settings.vedi.landingpage}VeDiLandingPage?idAplicacion=${
    VEDI_APP_ID[settings.vedi.ambient!]
}`;

export const RedirectPage = ({ url }: { url: string }) => {
    useEffect(() => {
        window.location.replace(url);
    }, [url]);

    return <h5 className="flex-center">Rediccionando...</h5>;
};

function Layout() {
    const user = useAppSelector(selectCurrentUser);
    return (
        <div className="app-container">
            <main className="main-wrapper">
                <Routes>
                    <Route path="/login" element={<RedirectPage url={URL} />} />
                    <Route path="/user-validation" element={<UserValidation />} />
                    <Route path="/unauthorized" element={<UnauthorizedPage />} />
                    <Route path="app/*" element={<ProtectedRoute user={user} />} />
                    <Route path="*" element={<p>Página no encontrada!</p>} />
                    <Route path="/" element={<Navigate to="app" replace />} />
                </Routes>
            </main>
            <Footer />
            <Alert />
        </div>
    );
}

export default Layout;
