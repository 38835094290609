import { Box } from '@mui/material';
import { memo, useMemo } from 'react';
import { CddDeleteIcon } from '../../assets/icons/Icons';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    DataGrid,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridColDef,
    GridRowParams
} from '@mui/x-data-grid';
import CustomNoResultsOverlay from './components/CustomNoResultsOverlay';
import CustomNoRowsOverlay from './components/CustomNoRowsOverlay';

const dataGridStyles = {
    '.MuiDataGrid-iconButtonContainer': {
        visibility: 'visible !important',
        width: 'auto !important'
    },
    '.MuiDataGrid-iconButtonContainer button': {
        padding: 0
    },
    '.MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
        color: '#000'
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'space-between !important'
    },
    '.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) svg': {
        opacity: '0.4 !important'
    },
    '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none !important'
    },
    '.MuiDataGrid-main': {
        marginTop: 0
    },
    '.MuiDataGrid-virtualScroller': {
        overflow: 'scroll !important'
    },
    '.MuiDataGrid-scrollbarFiller--header': {
        background: '#EDEDED !important'
    }
};

const dataGridContainerStyles = {
    height: 'inherit',
    width: '100%',
    '& .MuiDataGrid-root.MuiDataGrid-autoHeight': {
        height: 'inherit',
        overflow: 'auto'
    },
    '& .MuiDataGrid-main': {
        overflow: 'hidden'
    },
    '& .MuiDataGrid-columnHeader': {
        background: '#EDEDED'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 800
    },
    '& .flex-center': {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: '34px',
            width: '34px'
        }
    },
    '& .header-center': {
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'center !important'
        }
    },
    '& .MuiDataGrid-overlayWrapper': {}
};

const HandlerAction = ({
    deleteAction,
    viewAction,
    menuAction,
    ...props
}: GridActionsCellItemProps & {
    deleteAction?: any;
    viewAction?: any;
    menuAction?: any;
}) => {
    return (
        <>
            <GridActionsCellItem
                {...props}
                onClick={(params: any) => {
                    if (deleteAction) deleteAction(params);
                    if (viewAction) viewAction(params);
                    if (menuAction) menuAction(params);
                }}
            />
        </>
    );
};

interface DataTableProps {
    filterModelValue: string;
    loading: boolean;
    viewAction?: boolean;
    withActions?: string[];
    headers: any;
    options: any;
    handleViewAction?: (params: any) => void;
    handleDeleteAction: (params: any) => void;
    handleMenuAction?: (params: any, event: any) => void;
}

function DataTable({
    handleDeleteAction,
    handleViewAction,
    handleMenuAction,
    options,
    headers,
    withActions = [],
    viewAction = false,
    loading = false,
    filterModelValue
}: DataTableProps) {
    const columns = useMemo<GridColDef<(typeof options)[number]>[]>(() => {
        if (!!withActions?.length) {
            let auxHeaders = [...headers];
            auxHeaders.push({
                field: 'actions',
                headerName: 'ACCIONES',
                type: 'actions',
                width: 110,
                resizable: false,
                getActions: (params: GridRowParams) => {
                    if (viewAction) {
                        return [
                            <HandlerAction
                                icon={
                                    <VisibilityOutlinedIcon
                                        sx={{ fontSize: '22px', color: 'var(--primary)' }}
                                    />
                                }
                                viewAction={() => {
                                    if (handleViewAction) handleViewAction(params);
                                }}
                                label="view"
                            />,
                            <HandlerAction
                                icon={<CddDeleteIcon />}
                                deleteAction={() => {
                                    if (handleDeleteAction) handleDeleteAction(params);
                                }}
                                label="delete"
                            />
                        ];
                    } else if (withActions.includes('menu')) {
                        return [
                            <HandlerAction
                                icon={
                                    <KeyboardArrowDownIcon
                                        sx={{ fontSize: '22px', color: 'var(--primary)' }}
                                    />
                                }
                                menuAction={(event: any) => {
                                    if (handleMenuAction) handleMenuAction(params, event);
                                }}
                                label={`menuItemBtn-${params?.id}`}
                            />
                        ];
                    } else {
                        return [
                            <HandlerAction
                                icon={<CddDeleteIcon />}
                                deleteAction={() => {
                                    if (handleDeleteAction) handleDeleteAction(params);
                                }}
                                label="delete"
                            />
                        ];
                    }
                }
            });
            return auxHeaders;
        } else {
            return headers || [];
        }
    }, [handleDeleteAction, handleViewAction, handleMenuAction, headers, viewAction, withActions]);

    return (
        <>
            <Box sx={{ ...dataGridContainerStyles }}>
                <DataGrid
                    rows={options || []}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10
                            }
                        }
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    localeText={{ columnHeaderSortIconLabel: 'Ordenar' }}
                    sx={{ ...dataGridStyles }}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: 'Elementos por página',
                            labelDisplayedRows: (page: any) =>
                                `${page.from}-${page.to} de ${page.count} items`
                        },
                        toolbar: { showQuickFilter: true }
                    }}
                    filterModel={{
                        items: [],
                        quickFilterExcludeHiddenColumns: false,
                        quickFilterValues: filterModelValue?.trimEnd()?.split(' ')
                    }}
                    disableColumnMenu={true}
                    slots={{
                        noRowsOverlay: () => <CustomNoRowsOverlay />,
                        noResultsOverlay: () => <CustomNoResultsOverlay />
                    }}
                    disableRowSelectionOnClick
                    columnHeaderHeight={52}
                    rowHeight={52}
                    loading={loading}
                />
            </Box>
        </>
    );
}

export default memo(DataTable);
