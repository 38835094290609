import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { UserProp } from '../../utils/types';

type AuthState = {
    user: UserProp | null;
    token: string | null;
    refreshToken: string | null;
};

const initialState = { user: null, token: null, refreshToken: null } as AuthState;

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, { payload }) => {
            state.token = payload;
        },
        setRefreshToken: (state, { payload }) => {
            state.refreshToken = payload;
        },
        setUserData: (state, { payload }) => {
            state.user = payload;
        },
        logout: () => initialState
    }
});

export default authSlice.reducer;

export const { logout, setToken, setRefreshToken, setUserData } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;
