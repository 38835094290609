import {
    useAddApplicationMutation,
    useDeleteApplicationMutation,
    useGetApplicationsQuery,
    useUpdateApplicationMutation,
    useGetInactiveApplicationsQuery,
    useReactivateApplicationMutation
} from '../../../services/applicationsApi';

function useFetchs() {
    const {
        data: items,
        isLoading: loadingApplications,
        isFetching: fetchingApplications
    } = useGetApplicationsQuery();
    const [addApplication, { isLoading: addingApp }] = useAddApplicationMutation();
    const [deleteApplication, { isLoading: deletingApp }] = useDeleteApplicationMutation();
    const [updateApplication, { isLoading: updatingApp }] = useUpdateApplicationMutation();
    const [reactivateApplication, { isLoading: reactivatingApp }] =
        useReactivateApplicationMutation();

    const {
        data: inactiveApplications,
        isLoading: loadingInactiveApplications,
        isFetching: fetchingInactiveApplications
    } = useGetInactiveApplicationsQuery({
        inactivas: true
    });

    return {
        items,
        loadingApplications,
        fetchingApplications,
        deleteApplication,
        addApplication,
        updateApplication,
        reactivateApplication,
        addingApp,
        deletingApp,
        updatingApp,
        reactivatingApp,
        inactiveApplications,
        loadingInactiveApplications,
        fetchingInactiveApplications
    };
}

export default useFetchs;
