import { FileTypesHeaders } from '../../utils/headers';
import DataTable from '../../components/Table/DataTable';
import DialogCdd from '../../components/Dialog/DialogCdd';
import FileTypeForm from './components/FileTypeForm';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useFileTypes from './hooks/useFileTypes';

function Archivos() {
    const {
        items,
        filterModelValue,
        loadingFileTypes,
        fetchingFileTypes,
        openDialog,
        deletingFileType,
        addingFileType,
        handleSubmit,
        setOpenDialog,
        onInputChange,
        handleDelete,
        handleCloseDialog,
        ...restProps
    } = useFileTypes();

    return (
        <>
            <SectionTitle>
                Tipos de archivos soportados por la Web API. &nbsp;
                <em>Ejemplo: .pdf, .docx, .jpg, etc.</em>
            </SectionTitle>
            <SearchToolbar
                inputLabel="Buscar archivo"
                btnText="Nuevo Archivo"
                onButtonClick={setOpenDialog}
                onInputChange={onInputChange}
            />

            <DataTable
                handleDeleteAction={handleDelete}
                withActions={['delete']}
                headers={FileTypesHeaders}
                loading={loadingFileTypes || fetchingFileTypes || deletingFileType}
                filterModelValue={filterModelValue}
                options={items}
            />

            <DialogCdd
                title="Nuevo tipo de archivo"
                openDialog={openDialog}
                handleSubmit={handleSubmit}
                loading={addingFileType}
                handleCloseDialog={handleCloseDialog}
            >
                <FileTypeForm items={items} {...restProps} />
            </DialogCdd>
        </>
    );
}

export default Archivos;
