import { DocumentHeaders } from '../../utils/headers';
import DataTable from '../../components/Table/DataTable';
import DialogCdd from '../../components/Dialog/DialogCdd';
import DocumentosForm from './components/DocumentosForm';
import SearchToolbar from '../../components/SearchToolbar/SearchToolbar';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useDocumentTypes from './hooks/useDocumentTypes';
import ActionsMenu from '../../components/ActionsMenu/ActionsMenu';

function Documentos() {
    const {
        items,
        openDialog,
        loadingItems,
        fetchingItems,
        filterModelValue,
        addingDocumentType,
        deletingDocumentType,
        updatingDocumentType,
        onInputChange,
        handleSubmit,
        handleDelete,
        handleOpenDialog,
        handleCloseDialog,
        handleMenuAction,
        anchorEl,
        isEditing,
        handleCloseMenu,
        handleSubmitAction,
        setOpenDialog,
        data,
        handleDownloadExcel,
        ...restProps
    } = useDocumentTypes();

    return (
        <>
            <SectionTitle>
                Tipos de documentos aceptados por la Web API. &nbsp;
                <em>Ejemplo: Contratos, recibos, facturas, etc.</em>
            </SectionTitle>

            <SearchToolbar
                inputLabel="Buscar documento"
                btnText="Nuevo documento"
                onButtonClick={setOpenDialog}
                onInputChange={onInputChange}
                downloadButton={items && !!items.length}
                onDownloadButtonClick={handleDownloadExcel}
            />

            <DataTable
                handleDeleteAction={handleDelete}
                handleMenuAction={handleMenuAction}
                headers={DocumentHeaders}
                withActions={['menu']}
                loading={
                    loadingItems || fetchingItems || deletingDocumentType || updatingDocumentType
                }
                filterModelValue={filterModelValue}
                options={items}
            />

            <ActionsMenu
                anchorEl={anchorEl}
                handleCloseMenu={handleCloseMenu}
                handleSubmitAction={handleSubmitAction}
                options={['edit', 'delete']}
            />

            <DialogCdd
                title={isEditing ? `Editar ${data.nombre}` : 'Nuevo tipo de documento'}
                openDialog={openDialog}
                handleSubmit={handleSubmit}
                loading={addingDocumentType || updatingDocumentType}
                handleCloseDialog={handleCloseDialog}
            >
                <DocumentosForm {...restProps} isEditing={isEditing} data={data} />
            </DialogCdd>
        </>
    );
}

export default Documentos;
