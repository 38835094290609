import { Backdrop, CircularProgress } from '@mui/material';

function LoadingComponent() {
    return (
        <Backdrop
            sx={{
                '& svg': {
                    color: '#fff'
                },
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={true}
        >
            <CircularProgress size="3rem" />
        </Backdrop>
    );
}

export default LoadingComponent;
