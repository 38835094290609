import { Box, Input } from '@mui/material';
import Button from '../Button/Button';
import AddIcon from '@mui/icons-material/Add';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

const inputStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '24px',
    '& .MuiInputBase-root': {
        width: '404px',
        height: '55px'
    },
    '& .MuiInputBase-root:hover': {
        borderBottom: '1px solid #bababa'
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: '1px solid #bababa'
    },
    '& .MuiInputBase-root::after': {
        borderBottom: '1px solid var(--primary)'
    }
};

interface Props {
    inputLabel: string;
    btnText: string;
    onInputChange?: (value: any) => void;
    onButtonClick?: () => void;
    downloadButton?: boolean;
    onDownloadButtonClick?: (data: any, fileName: string) => void;
}

function SearchToolbar({
    inputLabel,
    btnText,
    onInputChange,
    onButtonClick,
    downloadButton,
    onDownloadButtonClick
}: Props) {
    return (
        <Box sx={{ ...inputStyles }}>
            <Input onChange={onInputChange} placeholder={inputLabel} />
            <div>
                {downloadButton && (
                    <Button
                        type="outlined"
                        onClick={onDownloadButtonClick}
                        startIcon={<SimCardDownloadOutlinedIcon />}
                        style={{ marginRight: 8 }}
                    >
                        Exportar excel
                    </Button>
                )}
                <Button onClick={onButtonClick} startIcon={<AddIcon />}>
                    {btnText}
                </Button>
            </div>
        </Box>
    );
}

export default SearchToolbar;
