import { Box } from '@mui/material';
import Button from '../Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

interface DialogProps {
    title: React.ReactElement | string;
    openDialog: boolean;
    handleCloseDialog: () => void;
    handleSubmit: () => void;
    cancelBtnText?: string;
    confirmBtnText?: string;
    children?: React.ReactNode;
    keepMounted?: boolean;
    loading: boolean;
    disabled?: boolean;
}

function DialogCdd({
    openDialog,
    handleCloseDialog,
    handleSubmit,
    title,
    cancelBtnText,
    confirmBtnText,
    children,
    keepMounted = true,
    loading = false,
    disabled = false
}: DialogProps) {
    return (
        <Dialog
            fullWidth
            open={openDialog}
            onClose={
                keepMounted
                    ? () => {
                          return;
                      }
                    : handleCloseDialog
            }
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    handleSubmit();
                }
            }}
            sx={{
                '& .title-container': {
                    color: 'var(--white)',
                    background: 'var(--primary)',
                    height: '41px',
                    padding: '16px 32px',
                    fontWeight: 700,
                    alignItems: 'center',
                    display: 'flex',
                    '& svg': {
                        marginRight: '5px'
                    }
                },
                '& .MuiDialogContent-root': {
                    paddingX: '32px !important',
                    paddingY: '24px !important',
                    background: '#F5F5F5'
                },
                '& .MuiDialogActions-root': {
                    padding: '8px 32px 32px 32px',
                    background: '#F5F5F5'
                }
            }}
        >
            <Box className="title-container">
                <h3>{title}</h3>
            </Box>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} type="outlined">
                    {cancelBtnText || 'Cancelar'}
                </Button>
                <Button disabled={disabled || loading} loading={loading}>
                    {confirmBtnText || 'Guardar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogCdd;
