import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';

export const fileTypesApi = createApi({
    reducerPath: 'fileTypes',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['fileTypes'],
    endpoints: (builder) => ({
        getFileTypes: builder.query<any, void>({
            query: () => ({
                url: 'tipo-archivo'
            }),
            providesTags: ['fileTypes'],
            transformResponse: (response: { data: any }) => response?.data
        }),
        addFileType: builder.mutation({
            query: (data) => ({
                url: 'tipo-archivo',
                method: 'POST',
                body: data
            }),
            invalidatesTags: (_, error) => (error ? [] : ['fileTypes'])
        }),
        deleteFileType: builder.mutation({
            query: (fileTypeId: number) => ({
                url: `tipo-archivo/${fileTypeId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['fileTypes'])
        }),
        updateFileType: builder.mutation({
            query: ({ data, fileTypeId }) => ({
                url: `tipo-archivo/${fileTypeId}`,
                body: data,
                method: 'PUT'
            }),
            invalidatesTags: (_, error) => (error ? [] : ['fileTypes'])
        })
    })
});
export const {
    useGetFileTypesQuery,
    useAddFileTypeMutation,
    useDeleteFileTypeMutation,
    useUpdateFileTypeMutation
} = fileTypesApi;
