const API = 'core-cdd-api/';
const ADMIN = 'admin/';
const AMBIENT = process.env.REACT_APP_AMBIENT;
const APP_HOST = process.env.REACT_APP_HOST;
const VEDI_AMBIENT = process.env.REACT_APP_VEDI_AMBIENT;
const VEDI_HOST = process.env.REACT_APP_VEDI_HOST;
const VEDI_LANDINGPAGE = process.env.REACT_APP_VEDI_LADINGPAGE_HOST;

export const settings = {
    ambient: AMBIENT,
    url: {
        host: APP_HOST,
        api: API,
        admin: ADMIN
    },
    vedi: {
        ambient: VEDI_AMBIENT,
        host: VEDI_HOST,
        landingpage: VEDI_LANDINGPAGE
    }
};
